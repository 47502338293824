import { Box, Button, Flex, Heading, Img, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import Link from 'next/link';
import React from 'react';
import { BsLink45Deg } from 'react-icons/bs';
import { FiArrowUpRight } from 'react-icons/fi';
import CalendarComponent from '../blocks/CalendarComponent';
import { DateFixedType } from '@/containers/landingPage/Calendar';
import LinkWrapper from './LinkWrapper';

interface Props {
  finalData: {
    speakerRole: any;
    picturesLink: any;
    websiteLink: any;
    speaker: any;
    venue: string;
    _id: string;
    event_name: string;
    date: string;
    link?: string;
    event_type?: string;
    date_to: string;
    description: string;
    event_highlights: any;
    date_fixed: DateFixedType;
  }[];
  setModalOpen: React.Dispatch<
    React.SetStateAction<{
      isOpened: boolean;
      data: never[];
    }>
  >;
  setSelectedDate?: React.Dispatch<React.SetStateAction<any>>;
  setHomeSelectedDate?: React.Dispatch<React.SetStateAction<string>>;
  setDefaultRender?: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultEventRender?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonth: React.Dispatch<React.SetStateAction<string>>;
  isHomePage?: boolean;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const EventsCalendarCard: React.FC<Props> = ({
  finalData,
  setModalOpen,
  setSelectedDate = () => {},
  setHomeSelectedDate,
  setDefaultRender,
  setDefaultEventRender,
  setSelectedMonth,
  isHomePage = false,
}) => {
  const getEventDate = React.useCallback(
    (date: string, dateType: DateFixedType, usedFor: 'date' | 'month') => {
      if (usedFor === 'date') {
        switch (dateType) {
          case 'Date':
            return new Date(date)?.getDate();
          case 'Month':
          case 'Year':
            return '';
          default:
            return new Date(date)?.getDate();
        }
      } else {
        switch (dateType) {
          case 'Date':
          case 'Month':
            return months[new Date(date)?.getMonth()];
          case 'Year':
            return new Date(date)?.getFullYear();
          default:
            return months[new Date(date)?.getMonth()];
        }
      }
    },
    []
  );

  return (
    <Box w="full" pt="0">
      <Flex mb="30px" gap="20px">
        <Text borderTop="solid 4px #56b6a5">IVCA Initiatives</Text>
        <Text borderTop="solid 4px #64648c">Partnered Initiatives</Text>
      </Flex>
      <Flex
        columnGap={{
          md: isHomePage ? '14px' : '20px',
          lg: isHomePage ? '20px' : '50px',
          xl: '36px',
        }}
        w="100%"
        role="group"
        flexShrink="1"
        flexDir={{ xs: 'column-reverse', lg: 'row' }}
        rowGap={isHomePage ? '40px' : '25px'}
        sx={{
          '.events-container': {
            paddingRight: {
              md: isHomePage ? '14px' : '20px',
              lg: isHomePage ? '20px' : '50px',
              xl: '36px',
            },
            '&::-webkit-scrollbar': {
              width: '3px',
            },
            '&::-webkit-scrollbar-track': {
              bgColor: 'bg.seperatorBg',
            },
            '&::-webkit-scrollbar-thumb': {
              bgColor: 'primary.500',
            },
            '&::-webkit-scrollbar-thumb:hover': {},
          },
        }}
      >
        <Flex
          rowGap={{ xs: '21px', md: '28px' }}
          flexDir="column"
          maxW="795px"
          w={{ xs: '96%', sm: '100%' }}
          pl={{ xs: '10px', sm: '0px' }}
          maxH="500px"
          overflowY="auto"
          overflowX="visible"
          className="events-container"
        >
          {finalData?.length === 0 ? (
            <Flex w="100%" h="100%" alignItems="center" justify="center">
              <Heading
                as="h2"
                w={{ xs: '100%', lg: '450px' }}
                variant="milestone"
                textAlign="center"
              >
                No Initiatives Available
              </Heading>
            </Flex>
          ) : (
            finalData?.map((event, id) => (
              <LinkWrapper key={id} link={event?.link}>
                <Flex
                  boxShadow="0px 4px 84px rgba(100, 100, 140, 0.13)"
                  role="group"
                  w={{
                    xs: '100%',
                    sm: '95%',
                    md: '100%',
                    xl: isHomePage ? '720px' : '820px',
                  }}
                  maxW="100%"
                  // minH="162px"
                  pt={{
                    xs: '16px',
                    sm: '20px',
                    md: '24px',
                    lg: '28px',
                    xl: '30px',
                  }}
                  pl={{
                    xs: '16px',
                    sm: '20px',
                    md: '14px',
                    lg: '20px',
                    xl: '20px',
                  }}
                  pb={{
                    xs: '16px',
                    sm: '20px',
                    md: '24px',
                    lg: '28px',
                    xl: '30px',
                  }}
                  pr={{
                    xs: '16px',
                    sm: '20px',
                    md: '24px',
                    lg: '28px',
                    xl: '30px',
                  }}
                  justify="space-between"
                  columnGap={{
                    xs: '2px',
                    sm: '10px',
                  }}
                  borderLeft={
                    event?.event_type === 'Partner'
                      ? 'solid 8px #64648c'
                      : 'solid 8px #56b6a5'
                  }
                  mb="20px"
                  borderRadius="7px"
                  _hover={{
                    boxShadow: '24px 24px 84px rgba(78, 78, 96, 0.33)',
                  }}
                >
                  <Flex
                    w="100%"
                    flexDir={{ xs: 'column', md: 'row' }}
                    rowGap={{ xs: '15px', sm: '20px' }}
                    alignItems={
                      event?.description === '' &&
                      event?.event_highlights?.length === 0
                        ? { xs: 'flex-start', md: 'center' }
                        : 'flex-start'
                    }
                  >
                    {!event?.date_to ? (
                      <Box flexShrink="0" minW="95px">
                        {getEventDate(
                          event?.date,
                          event?.date_fixed,
                          'date'
                        ) && (
                          <Heading
                            variant="date"
                            lineHeight={{ xs: '40px', lg: '45px' }}
                            fontSize="42px"
                            mb="16px"
                            textAlign={{
                              xs: 'left',
                              md: 'center',
                            }}
                          >
                            {getEventDate(
                              event?.date,
                              event?.date_fixed,
                              'date'
                            )}
                          </Heading>
                        )}
                        <Text
                          variant="month"
                          fontSize={
                            getEventDate(event?.date, event?.date_fixed, 'date')
                              ? '16px'
                              : '24px'
                          }
                          textAlign={{
                            xs: 'left',
                            md: 'center',
                          }}
                        >
                          {getEventDate(
                            event?.date,
                            event?.date_fixed,
                            'month'
                          )}
                        </Text>
                      </Box>
                    ) : new Date(event?.date).getMonth() ===
                      new Date(event?.date_to).getMonth() ? (
                      <Flex
                        flexDir={{ xs: 'row', md: 'column' }}
                        flexShrink="0"
                        minW="95px"
                        alignItems={{ xs: 'center', md: 'center' }}
                        columnGap="10px"
                        _groupHover={{ color: 'primary.500' }}
                      >
                        <Box>
                          <Heading
                            as="h2"
                            variant="date"
                            lineHeight={{ xs: '40px', md: '50px' }}
                            fontSize="42px"
                            textAlign={{ xs: 'left', md: 'center' }}
                          >
                            {new Date(event?.date).getDate()}{' '}
                          </Heading>
                          <Text
                            variant="month"
                            fontSize="16px"
                            textAlign={{
                              xs: 'left',
                              md: 'center',
                            }}
                          >
                            {months[new Date(event?.date).getMonth()]}
                          </Text>
                        </Box>
                        <Text
                          color="#3D3D5F"
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="19.6px"
                          textAlign="center"
                          my="3px"
                          mr={{ xs: '10px', md: '0px' }}
                        >
                          to
                        </Text>
                        <Box>
                          <Heading
                            as="h2"
                            variant="date"
                            lineHeight={{ xs: '40px', md: '50px' }}
                            fontSize="42px"
                            textAlign={{ xs: 'left', md: 'center' }}
                          >
                            {new Date(event?.date_to).getDate()}
                          </Heading>
                          <Text
                            variant="month"
                            fontSize="16px"
                            textAlign={{
                              xs: 'left',
                              md: 'center',
                            }}
                          >
                            {months[new Date(event?.date).getMonth()]}
                          </Text>
                        </Box>
                      </Flex>
                    ) : (
                      <Flex
                        flex-Dir={{ xs: 'row', md: 'column' }}
                        flex-Shrink="0"
                        minW="95px"
                        justify={{
                          xs: 'flex-start',
                          md: 'center',
                        }}
                      >
                        <Flex flexDir="column" alignItems="center">
                          <Heading
                            as="h2"
                            variant="date"
                            lineHeight={{ xs: '40px', md: '50px' }}
                            fontSize="42px"
                            mb="8px"
                            textAlign={{
                              xs: 'left',
                              md: 'center',
                            }}
                          >
                            {new Date(event?.date).getDate()}
                            <Text
                              variant="month"
                              fontSize="16px"
                              textAlign={{
                                xs: 'left',
                                md: 'center',
                              }}
                            >
                              {months[new Date(event?.date).getMonth()]}
                            </Text>
                          </Heading>
                        </Flex>
                        <Text
                          color="#3D3D5F"
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="19.6px"
                          textAlign="center"
                          my="3px"
                        >
                          to
                        </Text>
                        <Flex flexDir="column" alignItems="center">
                          <Heading
                            as="h2"
                            variant="date"
                            lineHeight={{ xs: '40px', md: '50px' }}
                            fontSize={{ xs: '30px', lg: '42px' }}
                            mb="8px"
                            textAlign={{
                              xs: 'left',
                              md: 'center',
                            }}
                          >
                            {new Date(event?.date_to).getDate()}
                            <Text
                              variant="month"
                              fontSize={{ xs: '18px', md: '16px' }}
                              textAlign={{
                                xs: 'center',
                                sm: 'left',
                                md: 'center',
                              }}
                            >
                              {months[new Date(event?.date_to).getMonth()]}
                            </Text>
                          </Heading>
                        </Flex>
                      </Flex>
                    )}
                    <Box
                      w="1px"
                      h="100%"
                      ml="8px"
                      mr={{ md: '18px', lg: '30px' }}
                      bgColor="bg.grayishBlack"
                      display={{ xs: 'none', md: 'block' }}
                    />
                    <Box
                      alignSelf={{
                        xs: 'flex-start',
                        md:
                          event?.description === '' ||
                          event?.event_highlights?.length !== 0
                            ? 'center'
                            : 'flex-start',
                      }}
                    >
                      {event?.venue && (
                        <Heading
                          as="h4"
                          variant="footerTitle"
                          color="text.lightGray"
                          textTransform="none"
                          mb="13px"
                        >
                          {event?.venue}
                        </Heading>
                      )}
                      <Heading
                        as="h3"
                        fontSize="19px"
                        fontWeight="800"
                        lineHeight="28.5px"
                        color="text.mediumBlack"
                      >
                        {event?.event_name}
                      </Heading>
                      {event?.description && (
                        <Text
                          variant="featureText"
                          color="text.mediumBlackAlpha"
                          pt="8px"
                          dangerouslySetInnerHTML={{
                            __html: event?.description,
                          }}
                        />
                      )}
                      <Flex
                        columnGap="12px"
                        w="100%"
                        mt="20px"
                        flexDir={{ xs: 'column', md: 'row' }}
                        rowGap="16px"
                      >
                        <Flex
                          columnGap="16px"
                          rowGap="16px"
                          flexDir={{ xs: 'column', md: 'row' }}
                        >
                          {event?.event_highlights?.length > 0 && (
                            <Flex
                              _hover={{
                                cursor: 'pointer',
                              }}
                              flexShrink="0"
                              w={{ xs: '100%', md: '87px' }}
                              maxW="200px"
                              rowGap="5px"
                              h={{ xs: '100%', md: '85px' }}
                              flexDir={{ xs: 'row', md: 'column' }}
                              align={{ xs: 'center', md: 'flex-start' }}
                              cursor="pointer"
                              borderRadius="6px"
                              justify="space-between"
                              pt={{ xs: '8px', md: '12px' }}
                              pb={{ xs: '8px', md: '6px' }}
                              px="8px"
                              bg="linear-gradient(304.14deg, rgba(57, 148, 132, 0.9) 34.59%, rgba(79, 88, 139, 0.9) 69.52%)"
                              onClick={() => {
                                setModalOpen({
                                  isOpened: true,
                                  data: event?.event_highlights,
                                });
                              }}
                            >
                              <Heading
                                as="h3"
                                fontSize={{
                                  xs: '16px',
                                  sm: '15px',
                                  md: '13px',
                                }}
                                fontWeight="700"
                                lineHeight="16px"
                                color="white"
                              >
                                Initiative Highlights
                              </Heading>
                              <FiArrowUpRight color="white" size="30px" />
                            </Flex>
                          )}
                          {event?.websiteLink && (
                            <Flex
                              flexShrink="0"
                              w={{ xs: '100%', md: '87px' }}
                              h={{ xs: '100%', md: '85px' }}
                              pl="16px"
                              pt="13px"
                              pr="6px"
                              pb="14px"
                              flexDir={{ xs: 'row', md: 'column' }}
                              alignItems="center"
                              justifyContent="space-between"
                              borderWidth="1px"
                              borderColor="transparent"
                              borderRadius="4px"
                              role="group"
                              _groupHover={{
                                cursor: 'pointer',
                                bgColor: 'bg.veryLightPrimary',
                                borderStyle: 'solid',
                              }}
                            >
                              <Heading
                                as="h3"
                                fontSize={{
                                  xs: '16px',
                                  sm: '15px',
                                  md: '13px',
                                }}
                                fontWeight="700"
                                lineHeight="15.6px"
                                color="text.mediumBlack"
                                _groupHover={{ color: 'primary.500' }}
                              >
                                View Website
                              </Heading>
                              <BsLink45Deg size="25px" color="#7E8EE8" />
                            </Flex>
                          )}
                        </Flex>
                        {event?.speaker && event?.speakerRole && (
                          <Flex
                            pl="13px"
                            pt="13px"
                            pb="12px"
                            pr={{
                              xs: '15px',
                              lg:
                                event?.websiteLink && event?.picturesLink
                                  ? '20px'
                                  : '80px',
                              xl:
                                event?.websiteLink && event?.picturesLink
                                  ? '20px'
                                  : '105px',
                            }}
                            columnGap="16px"
                            bgColor="customGray.bg"
                            _groupHover={{ bgColor: 'bg.veryLightPrimary' }}
                          >
                            <Img
                              src="/svg/mic.svg"
                              alt="mic icon"
                              w="12px"
                              h="21px"
                              mt="4px"
                            />
                            <Box>
                              <Heading
                                as="h5"
                                fontSize="14px"
                                fontWeight="700"
                                lineHeight="21px"
                                color="text.mediumBlack"
                              >
                                {event?.speaker},
                              </Heading>
                              <Text
                                fontSize="13px"
                                fontWeight="500"
                                lineHeight="18.5px"
                                color="text.mediumBlack"
                              >
                                {event?.speakerRole}
                              </Text>
                            </Box>
                          </Flex>
                        )}
                      </Flex>
                    </Box>
                  </Flex>
                </Flex>
              </LinkWrapper>
            ))
          )}
        </Flex>
        <Flex columnGap={{ md: '25px', lg: '39px' }}>
          <Box
            as="main"
            justifySelf="flex-end"
            textAlign={{ xs: 'center', md: 'left' }}
            maxW={{ xs: '95%', sm: '350px' }}
            mx="auto"
          >
            {!isHomePage && (
              <Heading
                as="h3"
                mb="8px"
                variant="milestone"
                color="text.darkHead"
              >
                Initiatives Calendar
              </Heading>
            )}
            {!isHomePage && (
              <Text
                mb="20px"
                variant="featureText"
                color="text.mediumBlackAlpha"
                px={{ xs: '20px', md: '0px' }}
              >
                You can use Calender to find the desired initiative & get the
                required information easily.
              </Text>
            )}
            <Box
              order={{ xs: 1, md: 2 }}
              maxW={{
                xs: '100%',
                sm: '340px',
                md: '340.78px',
                lg: '340.78px',
              }}
              flexShrink="0"
              alignSelf={{ xs: 'center', lg: 'flex-start' }}
              justifySelf="center"
            >
              <CalendarComponent
                setHomeSelectedDate={
                  isHomePage ? setHomeSelectedDate : undefined
                }
                setSelectedDate={setSelectedDate}
                setDefaultRender={isHomePage ? setDefaultRender : undefined}
                setDefaultEventRender={
                  isHomePage ? undefined : setDefaultEventRender
                }
                checkUpcomingDates={finalData.map((item: any) =>
                  format(new Date(item?.date), 'yyyy-LL-dd')
                )}
                passSelectedMonth={setSelectedMonth}
                eventsDate={finalData.filter(
                  (item) =>
                    item?.date_fixed === '' || item?.date_fixed === 'Date'
                )}
              />
              {isHomePage && (
                <Link href="/initiatives">
                  <Button
                    mt="40.74px"
                    w="100%"
                    colorScheme="primary"
                    height="55px"
                    borderRadius="0px"
                    onClick={() => {
                      if (setDefaultRender) {
                        setDefaultRender(true);
                      }
                      setSelectedMonth('');
                    }}
                  >
                    View all Initiatives
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default EventsCalendarCard;
